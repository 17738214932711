import React, { FC } from 'react';
import styled from 'styled-components';
import { LoadingCircle } from './loading';
import AppPageHeader from './texts/appPageHeader';

const PaymentLoadingWrapper = styled.div`
    align-items: center;
    background-color: rgba(128, 128, 128, 0.7);
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
`;
const CircleWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

interface PaymentLoadingInterface {
    text: string;
}

const PaymentLoading: FC<PaymentLoadingInterface> = ({ text }) => (
	<PaymentLoadingWrapper>
		<div>
			<CircleWrapper>
				<LoadingCircle />
			</CircleWrapper>
			<AppPageHeader>
				{text}
			</AppPageHeader>
		</div>

	</PaymentLoadingWrapper>
);
export default PaymentLoading;
