import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { clearLayoutAction, setLayoutAction } from '../../state/layout.reducer';
import SEO from '../../components/seo';
import { Loading } from '../../components/common/loading';
import { getQueryParam } from '../../utils/queryParams';
import { LINKS } from '../../constants/links';
import { AppContainer } from '../../components/common/appContainer';
import { fetchOrdersByTableIdAction, setTableIdAction } from '../../state/orders.reducer';
import {
	callAWaiterAction,
	clearNotificationVisibleAction,
	divideBillAction,
	setOrderData,
} from '../../state/order.reducer';
import MultipleBillsWrapper from '../../components/order/multipleBillsWrapper';
import { GlobalState } from '../../state/@types/redux.interface';
import { fetchTableDetailsByIdAction } from '../../state/table.reducer';
import useGetIdsFromUrlAndFetch from '../../utils/useGetIdsFromUrlAndFetch';
import useRefState from '../../utils/useRefState';

const StyledActualizationNotification = styled.div`
	background-color: ${({ theme }) => theme.colors.brandPurple};
	border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
	color: #fff;
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 31px;
	padding-left: 20px;
	padding-right: 10px;
	position: fixed;
	right: 0;
	top: 91px;
	z-index: 9999;

	span {
		padding-left: 20px;
	}
`;

let interval;

const Orders: FC = () => {
	useGetIdsFromUrlAndFetch();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const tableSelector = useSelector((state: GlobalState) => state.table);
	const ordersSelector = useSelector((state: GlobalState) => state.orders);
	const orderSelector = useSelector((state: GlobalState) => state.order);
	const {
		table, fetched: fetchedTable,
	} = tableSelector;

	const {
		loading, fetched, error, orders, currentOrder,
	} = ordersSelector;

	const {
		actualizedNotificationVisible, waiterCalledNotificationVisible, order, loadedOnce,
	} = orderSelector;

	const tableIdParam = getQueryParam('tableId');

	const [currentOrderNumber, setCurrentOrderNumber] = useRefState(0);
	const [currentOrders, setCurrentOrders] = useRefState(null);

	const getOrdersAtStart = async () => {
		dispatch(fetchOrdersByTableIdAction(tableIdParam));
	};

	useEffect(() => {
		getOrdersAtStart();
	}, []);

	useEffect(() => {
		setCurrentOrderNumber(currentOrder ?? 0);
	}, [currentOrder]);

	useEffect(() => {
		setCurrentOrders(orders);
	}, [orders]);

	useEffect(() => {
		if (table && fetchedTable) {
			dispatch(fetchOrdersByTableIdAction(table.id));
		}
	}, [table]);

	useEffect(() => {
		if (fetchedTable && fetched && !loadedOnce) {
			dispatch(setOrderData(orders[0]));
		}
	}, [fetchedTable, fetched]);

	// const restaurant = useSelector((state: GlobalState) => state.restaurant);
	// const {
	// 	data, error, fetched, loadedOnce, actualizedNotificationVisible, waiterCalledNotificationVisible, loaderVisible,
	// } = order;
	// const { id, positions } = data;
	//
	// const topOptions = [];

	const clearFetchInterval = () => {
		if (interval) {
			clearInterval(interval);
			interval = null;
		}
	};

	const fetchTableOrders = () => {
		dispatch(fetchOrdersByTableIdAction(tableIdParam));
		dispatch(setOrderData(currentOrders.current[currentOrderNumber.current ?? 0]));
	};

	const setFetchInterval = () => {
		interval = setInterval(fetchTableOrders, 5000);
	};

	const clearNotificationMessage = () => {
		dispatch(clearNotificationVisibleAction());
	};

	useEffect(() => {
		setFetchInterval();
		return () => {
			clearFetchInterval();
		};
	}, []);
	//
	// const openMenu = () => {
	// 	navigate(`${Links.RESTAURANT}/${restaurant.data.slug}${Links.MENU}`);
	// };
	//
	// const divideBill = () => {
	// 	dispatch(divideBillAction());
	// };
	//
	// const callAWaiter = () => {
	// 	dispatch(callAWaiterAction(id));
	// };
	//
	// useEffect(() => {
	// 	if (orders.length) dispatch(setOrderData(orders[currentOrder]));
	// }, [orders, currentOrder]);
	//
	// useEffect(() => {
	// 	topOptions.push({
	// 		label: t('order.callWaiter'),
	// 		onClick: callAWaiter,
	// 	});
	// 	topOptions.push({
	// 		label: t('restaurant.restaurantMenu'),
	// 		onClick: openMenu,
	// 	});
	// 	if (positions && (positions.length > 1 || positions[0]?.count > 1)) {
	// 		topOptions.push({
	// 			label: t('multipleOrders.divide'),
	// 			onClick: divideBill,
	// 		});
	// 	}
	// 	if (restaurant?.data) {
	// 		dispatch(
	// 			setLayoutAction({
	// 				customLogoSrc: data?.images?.find((image) => image.type === 'LOGO')?.url || null,
	// 				customLogoUrl: restaurant.data.slug ? `${Links.RESTAURANT}/${restaurant.data.slug}` : null,
	// 				topOptions: topOptions.length ? topOptions : null,
	// 			}),
	// 		);
	// 	}
	// 	return () => {
	// 		dispatch(clearLayoutAction());
	// 	};
	// }, [restaurant, order]);
	//
	// useEffect(() => {
	// 	dispatch(setTableIdAction(tableId));
	// }, [tableId]);

	return (
		<>
			<SEO title={t('order.title')} />
			{actualizedNotificationVisible && (
				<StyledActualizationNotification
					onClick={clearNotificationMessage}
				>
					<span>{t('order.billActualized')}</span>
					<span className="close">X</span>
				</StyledActualizationNotification>
			)}
			{waiterCalledNotificationVisible && (
				<StyledActualizationNotification onClick={clearNotificationMessage}>
					<span>{t('order.waiterCalled')}</span>
					<span className="close">X</span>
				</StyledActualizationNotification>
			)}
			{/* {loading && <Loading text={t('order.loadingText')} />} */}
			<AppContainer>{orders && orders?.length > 0 && <MultipleBillsWrapper />}</AppContainer>
		</>
	);
};
export default Orders;
