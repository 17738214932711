import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowLeft from '../../icons/arrow-left.svg';
import ArrowRight from '../../icons/arrow-right.svg';
import { changeCurrentOrder } from '../../state/orders.reducer';
import { clearDivideBillAction, setOrderData, setTipAction } from '../../state/order.reducer';
import { clearNewOrderAction } from '../../state/newOrder.reducer';

const StyledOrderSwitcher = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -16px;
  overflow: hidden;
  position: relative;
  width: calc(100% + 32px);
  svg {
    cursor: pointer;
    margin: 20px;
  }
`;
const StyledOrderSwitcherMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
const BillHeader = styled.h1`
  color: ${({ theme }) => theme.colors.brandPurple};
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 1.7rem;
  margin: 0;
  text-align: center;
  white-space: nowrap;
`;
const BillSideHeader = styled(BillHeader)`
  color: ${({ theme }) => theme.colors.fontGray};
  cursor: pointer;
  font-size: 1.1rem;
  position: absolute;
`;
const OrderSwitcher = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const table = useSelector((state) => state.orders);
	const { orders, currentOrder } = table;

	const handleOrderChange = (newOrderNumber) => {
		if (orders.length > newOrderNumber && newOrderNumber >= 0) {
			dispatch(changeCurrentOrder(newOrderNumber));
			dispatch(clearDivideBillAction());
			dispatch(setTipAction(0));
			dispatch(clearNewOrderAction());
			dispatch(setOrderData(orders[newOrderNumber]));
		}
	};

	return (
		<StyledOrderSwitcher>
			{currentOrder > 0 && (
				<BillSideHeader
					onClick={() => handleOrderChange(currentOrder - 1)}
					style={{ transform: 'translate(-150%, 0%)' }}
				>
					{t('multipleOrders.billHeader')}
					{currentOrder}
				</BillSideHeader>
			)}
			<StyledOrderSwitcherMain>
				<ArrowLeft onClick={() => handleOrderChange(currentOrder - 1)} />
				<BillHeader>
					{t('multipleOrders.billHeader')}
					{currentOrder + 1}
				</BillHeader>
				<ArrowRight onClick={() => handleOrderChange(currentOrder + 1)} />
			</StyledOrderSwitcherMain>
			{currentOrder < orders.length - 1 && (
				<BillSideHeader
					onClick={() => handleOrderChange(currentOrder + 1)}
					style={{ transform: 'translate(150%, 0%)' }}
				>
					{t('multipleOrders.billHeader')}
					{currentOrder + 2}
				</BillSideHeader>
			)}
		</StyledOrderSwitcher>
	);
};

export default OrderSwitcher;
