import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { fetchPaymentUrlAction } from '../../state/payment.reducer';
import AppButton from '../common/button';
import AppPageHeader from '../common/texts/appPageHeader';
import { LINKS } from '../../constants/links';
import { LoadingCircle } from '../common/loading';
import { isUserLogged, setNip } from '../../utils/localStorage';
import { clearCookiesBeforeLogin, getCookiesBeforeLogin, setCookiesBeforeLogin } from '../../utils/cookies';
import { PAYMENT_TYPES } from '../../constants/paymentTypes';
import { sendDivideBillAction } from '../../state/newOrder.reducer';
import { fetchOrdersByTableIdAction } from '../../state/orders.reducer';
import { getQueryParam } from '../../utils/queryParams';
import PaymentLoading from '../common/paymentLoading';
import { GlobalState } from '../../state/@types/redux.interface';
import ThankYouForComing from './thankYouForComing';
import Regulation from './regulation';
import TotalPrice from './totalPrice';
import ChooseTip from './tips/choose-tip';
import PosIntegrationBill from './posIntegrationBill';
import GetInvoice from './getInvoice';
import OrderHistoryRating from './orderHistoryRating';
import OrderSwitcher from './orderSwitcher';

const StyledMultipleBillWrapper = styled.div`
  padding: 0 0 30px;
`;
const StyledButtonsWrapper = styled.div`
  padding-top: 45px;
  text-align: center;
`;
const StyledRestpayButton = styled(AppButton)`
  margin-bottom: 25px;
`;
const StyledBillLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const StyledErrorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.fontRed};
  font-weight: 600;
  padding: 20px;
  text-align: center;
`;
const StyledBillNotEnteredText = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;
const OrderStatusWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const MultipleBillsWrapper: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ordersSelector = useSelector((state: GlobalState) => state.orders);
	const { orders } = ordersSelector;

	const order = useSelector((state: GlobalState) => state.order);
	const {
		data, divide, billEntered, loading: issueAnInvoice, invoiceNip, invoiceNipValid,
	} = order;
	const {
		total, currency, tip, id, isPaid, paymentInProgress, paymentError,
	} = data;

	const newOrder = useSelector((state: GlobalState) => state.newOrder);
	const {
		positions, total: newTotal, success, id: newOrderId,
	} = newOrder;

	const payment = useSelector((state: GlobalState) => state.payment);
	const { loading: paymentLoading, redirect: paymentRedirect } = payment;

	const handleSendDivide = () => {
		dispatch(sendDivideBillAction(id, positions));
	};

	const handleNotLogged = (orderId, price, tip, paymentType, nip) => {
		setCookiesBeforeLogin(orderId, price, tip, paymentType, nip);
		navigate(LINKS.LOGIN);
	};

	const generatePaymentUrl = (paymentType) => {
		const nip = order.issueAnInvoice && invoiceNipValid && invoiceNip ? invoiceNip : null;
		const isLogged = isUserLogged();
		if (nip) {
			setNip(nip);
		}
		if (!isLogged) {
			handleNotLogged(id, total, tip, paymentType, nip);
		} else {
			dispatch(fetchPaymentUrlAction(divide ? newOrderId : id, total, tip, paymentType, nip));
		}
	};

	useEffect(() => {
		const tableId = getQueryParam('tableId');
		if (success) {
			dispatch(fetchOrdersByTableIdAction(tableId));
			generatePaymentUrl(PAYMENT_TYPES.STRIPE_CARD);
		}
	}, [success]);

	const handleOperationAfterLogin = () => {
		const {
			orderId, price, tip, paymentType, nip,
		} = getCookiesBeforeLogin();
		clearCookiesBeforeLogin();
		if (!orderId || !price || !paymentType || !isUserLogged()) {
			return;
		}
		dispatch(fetchPaymentUrlAction(orderId, price, parseInt(tip, 10), paymentType, nip));
	};

	useEffect(() => {
		handleOperationAfterLogin();
	}, []);

	return (
		<StyledMultipleBillWrapper>
			<ThankYouForComing
				billEntered={billEntered}
				data={data}
			/>
			{data?.status && (
				<OrderStatusWrapper>
					{t('orderStatuses.status')}
					{': '}
					{t(`orderStatuses.${data?.status?.toLowerCase()}`)}
				</OrderStatusWrapper>
			)}
			<>
				<br />
				{orders.length > 1 ? <OrderSwitcher /> : <AppPageHeader>{t('order.billHeader')}</AppPageHeader>}
				{billEntered && (
					<>
						<PosIntegrationBill isPaid={isPaid} />
						`
						{!divide && paymentError && <StyledErrorWrapper>{t('order.paymentFailed')}</StyledErrorWrapper>}
						{!divide && paymentInProgress && <StyledErrorWrapper>{t('order.paymentProcessing')}</StyledErrorWrapper>}
						{!isPaid && !paymentInProgress && data?.status !== 'CREATED' && (
							<>
								<ChooseTip />
								<TotalPrice
									currency={currency}
									tip={tip}
									total={divide ? newTotal : total}
								/>
								<GetInvoice />
								<StyledButtonsWrapper>
									{!divide && (
										<StyledRestpayButton
											as="button"
											onClick={() => generatePaymentUrl(PAYMENT_TYPES.STRIPE_CARD)}
										>
											{t('order.payButton')}
										</StyledRestpayButton>
									)}
									{divide && (
										<StyledRestpayButton
											as="button"
											disabled={positions.length < 1}
											onClick={handleSendDivide}
										>
											{t('order.payButton')}
										</StyledRestpayButton>
									)}
								</StyledButtonsWrapper>
								<Regulation />
							</>
						)}
					</>
				)}

				{!divide && isPaid && <OrderHistoryRating />}
				{!divide && !billEntered && !(paymentLoading || paymentRedirect) && (
					<StyledBillLoadingWrapper>
						<StyledBillNotEnteredText>{t('order.billNotEnteredYet')}</StyledBillNotEnteredText>
						<LoadingCircle />
					</StyledBillLoadingWrapper>
				)}
				{!divide && (paymentLoading || paymentRedirect) && (
					<PaymentLoading text={t('order.paymentProcessing')} />
				)}
			</>
		</StyledMultipleBillWrapper>
	);
};

export default MultipleBillsWrapper;
